import scrollIntoView from 'smooth-scroll-into-view-if-needed';

const scrollTo = (anchor) => {
	if (anchor) {
		scrollIntoView(anchor, {
			scrollMode: 'if-needed',
			block: 'start',
			duration: 1000,
		});
	}
};

export default scrollTo;
