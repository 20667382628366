/*
  Project: Milanowski
  Author: WebCrafters Studio
 */

import greet from './modules/greeting';
import scrollTo from './modules/scrollto';

// Back Top
const anchorButton = document.querySelectorAll('.js-scroll-to-anchor');
if (anchorButton) {
	anchorButton.forEach((el) => {
		const btn = el;
		btn.onclick = (e) => {
			e.preventDefault();
			const anchor = document.querySelector(btn.dataset.anchor);
			if (!anchor) return;
			scrollTo(anchor);
		};
	});
}
// Mobile menu
const navMobileBtn = document.getElementById('js-nav-mobile-open');
if (navMobileBtn) {
	navMobileBtn.onclick = (event) => {
		event.target.classList.toggle('active');
		event.target.previousElementSibling.classList.toggle('active');
	};
}
const navMobileBtnClose = document.getElementById('js-nav-mobile-close');
if (navMobileBtnClose) {
	navMobileBtnClose.onclick = () => {
		navMobileBtn.click();
	};
}

//
greet('World');
